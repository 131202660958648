// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-class-actions-index-js": () => import("./../../../src/pages/class-actions/index.js" /* webpackChunkName: "component---src-pages-class-actions-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-practice-areas-index-js": () => import("./../../../src/pages/practice-areas/index.js" /* webpackChunkName: "component---src-pages-practice-areas-index-js" */),
  "component---src-pages-settlements-index-js": () => import("./../../../src/pages/settlements/index.js" /* webpackChunkName: "component---src-pages-settlements-index-js" */),
  "component---src-pages-updates-index-js": () => import("./../../../src/pages/updates/index.js" /* webpackChunkName: "component---src-pages-updates-index-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/blog-post-template.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-career-template-js": () => import("./../../../src/templates/career-template.js" /* webpackChunkName: "component---src-templates-career-template-js" */),
  "component---src-templates-case-template-js": () => import("./../../../src/templates/case-template.js" /* webpackChunkName: "component---src-templates-case-template-js" */),
  "component---src-templates-legal-template-js": () => import("./../../../src/templates/legal-template.js" /* webpackChunkName: "component---src-templates-legal-template-js" */),
  "component---src-templates-partner-template-js": () => import("./../../../src/templates/partner-template.js" /* webpackChunkName: "component---src-templates-partner-template-js" */),
  "component---src-templates-practice-area-template-js": () => import("./../../../src/templates/practice-area-template.js" /* webpackChunkName: "component---src-templates-practice-area-template-js" */),
  "component---src-templates-settlement-template-js": () => import("./../../../src/templates/settlement-template.js" /* webpackChunkName: "component---src-templates-settlement-template-js" */)
}

